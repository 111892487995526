import React from 'react';
import {
  PlainText,
  Props as PlainTextProps
} from 'components/data-display/plain-text';
import { Grid } from 'components/layout/grid';
import { Padding } from 'components/layout/padding';
import {
  ContentCard,
  Props as ContentCardProps
} from 'components/data-display/content-card';
import {
  ImageRow,
  Props as ImageRowProps
} from 'components/data-display/image-row';
import {
  RichText,
  Props as RichTextProps
} from 'components/data-display/rich-text';
import {
  TwoColumnList,
  Props as TwoColumnListProps
} from 'components/data-display/two-column-list';
import {
  RichTextAndImage,
  Props as RichTextAndImageProps
} from 'components/data-display/rich-text-and-image';
import {
  FullWidthImage,
  Props as FullWidthImageProps
} from 'components/data-display/full-width-image';
import {
  VerticalTabs,
  Props as VerticalTabsProps
} from 'components/data-display/vertical-tabs';
import {
  LinkList,
  Props as LinkListProps
} from 'components/data-display/link-list';
import {
  FileDownload,
  Props as FileDownloadProps
} from 'components/data-display/file-download';
import {
  RawHtml,
  Props as RawHtmlProps
} from 'components/data-display/raw-html';
export interface RichTextComponent extends RichTextProps {
  _key: string;
  _type: 'richText';
}

export interface RichTextAndImageComponent extends RichTextAndImageProps {
  _key: string;
  _type: 'twoColumnRichTextAndImage';
}

export interface TextBlockComponent {
  _key: string;
  _type: 'plainText';
}
export interface TwoCardSectionComponent {
  _key: string;
  _type: 'twoCardSection';
  cards: ContentCardProps[];
}

export interface ThreeCardSectionComponent {
  _key: string;
  _type: 'threeCardSection';
  cards: ContentCardProps[];
}

export interface FourCardSectionComponent {
  _key: string;
  _type: 'fourCardSection';
  cards: ContentCardProps[];
}

export interface ImageRowComponent {
  _key: string;
  _type: 'imageRow';
}

export interface HorizontalContentCardComponent {
  _key: string;
  _type: 'horizontalContentCard';
}

export interface FullWidthImageComponent extends FullWidthImageProps {
  _key: string;
  _type: 'fullWidthImage';
}

export interface SpacerComponent {
  _key: string;
  _type: 'spacer';
  size: 'spacer-small' | 'spacer-medium' | 'spacer-large';
}

export interface TwoColumnListComponent extends TwoColumnListProps {
  _key: string;
  _type: 'twoColumnList';
}

export interface VerticalTabsComponent extends VerticalTabsProps {
  _key: string;
  _type: 'verticalTabs';
}

export interface LinkListComponent extends LinkListProps {
  _key: string;
  _type: 'linkList';
}

export interface FileDownloadComponent extends FileDownloadProps {
  _key: string;
  _type: 'fileDownload';
}

export interface RawHtmlComponent extends RawHtmlProps {
  _key: string;
  _type: 'rawHtml';
}

type ComponentProps =
  | RichTextComponent
  | TwoColumnListComponent
  | RichTextAndImageComponent
  | TextBlockComponent
  | TwoCardSectionComponent
  | ThreeCardSectionComponent
  | FourCardSectionComponent
  | ImageRowComponent
  | SpacerComponent
  | FullWidthImageComponent
  | VerticalTabsComponent
  | LinkListComponent
  | FileDownloadComponent
  | HorizontalContentCardComponent
  | RawHtmlComponent;

interface ComponentFactoryProps {
  componentType: string;
  componentProps: ComponentProps;
}

/**
 * The ComponentFactory component is a factory for rendering different types of components.
 */
export const ComponentFactory: React.FC<ComponentFactoryProps> = ({
  componentType,
  componentProps
}) => {
  switch (componentType) {
    case 'plainText':
      return (
        <Padding vertical={false}>
          <PlainText {...(componentProps as unknown as PlainTextProps)} />
        </Padding>
      );
    case 'richText':
      return (
        <Padding vertical={false}>
          <RichText {...(componentProps as RichTextComponent)} />
        </Padding>
      );
    case 'twoColumnRichText':
      return (
        <Padding vertical={false}>
          <RichText {...(componentProps as RichTextComponent)} columns={2} />
        </Padding>
      );
    case 'contentCard':
      return (
        <Padding vertical={false}>
          <ContentCard {...(componentProps as unknown as ContentCardProps)} />
        </Padding>
      );
    case 'fullWidthImage':
      return (
        <FullWidthImage {...(componentProps as FullWidthImageComponent)} />
      );
    case 'verticalTabs':
      return <VerticalTabs {...(componentProps as VerticalTabsComponent)} />;
    case 'twoCardSection':
      return (
        <Padding vertical={false}>
          <Grid columns={2}>
            {(componentProps as TwoCardSectionComponent).cards.map(
              (card: ContentCardProps, index: React.Key | null | undefined) => (
                <ContentCard key={index} {...card} />
              )
            )}
          </Grid>
        </Padding>
      );
    case 'threeCardSection':
      return (
        <Padding vertical={false}>
          <Grid columns={3}>
            {(componentProps as ThreeCardSectionComponent).cards.map(
              (card: ContentCardProps, index: React.Key | null | undefined) => (
                <ContentCard key={index} {...card} />
              )
            )}
          </Grid>
        </Padding>
      );
    case 'fourCardSection':
      return (
        <Padding vertical={false}>
          <Grid columns={4}>
            {(componentProps as FourCardSectionComponent).cards.map(
              (card: ContentCardProps, index: React.Key | null | undefined) => (
                <ContentCard key={index} {...card} />
              )
            )}
          </Grid>
        </Padding>
      );
    case 'imageRow':
      return <ImageRow {...(componentProps as unknown as ImageRowProps)} />;
    case 'pdfDownload':
      return (
        <FileDownload
          // @ts-ignore
          {...(componentProps.pdf as unknown as FileDownloadProps)}
        />
      );
    case 'horizontalContentCard':
      return (
        <Padding vertical={false}>
          <ContentCard
            {...(componentProps as unknown as ContentCardProps)}
            orientation="landscape"
          />
        </Padding>
      );
    case 'spacer':
      switch ((componentProps as unknown as SpacerComponent).size) {
        case 'spacer-small':
          return <div style={{ height: '10px' }} />;
        case 'spacer-medium':
          return <div style={{ height: '20px' }} />;
        case 'spacer-large':
          return <div style={{ height: '30px' }} />;
        default:
          return null;
      }
    case 'twoColumnList':
      return (
        <Padding vertical={false}>
          <TwoColumnList {...(componentProps as TwoColumnListComponent)} />
        </Padding>
      );
    case 'linkList':
      return (
        <Padding vertical={false}>
          <LinkList {...(componentProps as LinkListComponent)} />
        </Padding>
      );
    case 'twoColumnRichTextAndImage':
      return (
        <Padding vertical={false}>
          <RichTextAndImage
            {...(componentProps as RichTextAndImageComponent)}
          />
        </Padding>
      );
    case 'rawHtml':
      return (
        <Padding vertical={false}>
          <RawHtml {...(componentProps as RawHtmlComponent)} />
        </Padding>
      );
    default:
      return null;
  }
};

export default ComponentFactory;
