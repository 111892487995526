import Image from 'next/image';
import classNames from 'classnames';

import type BrandFolderImage from 'lib/types/brandfolder-image';

/* Components */
import styles from './styles.module.scss';

const cx = classNames.bind(styles);

export interface Props extends React.ComponentProps<'div'> {
  /**
   * The image to display.
   */
  image: BrandFolderImage;
}

/**
 * The ImageRow component displays an image and some text along with an optional call to action.
 * It is designed to fill an entire row.
 */
export const FullWidthImage: React.FC<Props> = ({
  image,
  className,
  ...props
}: Props) => {
  return (
    <div
      className={cx(styles['full-width-image'], className)}
      data-testid="full-width-image"
      {...props}
    >
      <Image
        className={styles['image']}
        loading="lazy"
        placeholder="empty"
        fill
        src={image.url || ''}
        alt={image.alt_text || ''}
        sizes="640px"
      />
    </div>
  );
};

export default FullWidthImage;
